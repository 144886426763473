import { getClientLocales } from 'remix-utils/locales/server'
import { type LanguageKey, type StyleText } from '#types/lokalisation'

export function getLokalisedString(
	valueObj: StyleText,
	locale: string,
): string {
	if (locale && locale.split('-')[0] === 'en') {
		const localString = valueObj['en']
		if (localString != undefined) return localString
	}
	return valueObj['da-DK']
}

export function getFullLanguageName(language: string): string {
	if (language === 'en') return 'English'
	return 'Dansk'
}

export function getLanguageShortHand(language: string): string {
	if (language) return language.slice(0, 2).toLowerCase()
	return 'da-DK'
}

export function getLanguageFromShortHand(language: string): LanguageKey {
	if (language) return language.split('-')[0] === 'en' ? 'en' : 'da-DK'
	return 'da-DK'
}

export function getLanguageFromCookieOrBrowserOrFallback(
	cookieLanguage: string | undefined | null,
	request: Request,
  ): string {
	if (!cookieLanguage) {
	  const browserLanguages = getClientLocales(request);
	  const browserLanguage = browserLanguages!.length ? browserLanguages![0] : null;
	  if (browserLanguage && isAcceptedLanguage(browserLanguage)) {
		return browserLanguage;
	  }
	} else if (isAcceptedLanguage(cookieLanguage)) {
	  return cookieLanguage;
	}
	return 'da'; 
  }

export function isAcceptedLanguage(language: string): boolean {
	const acceptedLanguages = ['en', 'da'];
	const lang = language.split('-')[0];
	return acceptedLanguages.includes(lang);
  }
